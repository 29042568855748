.customLogo {
  position: fixed;
  height: 0px;
  padding-bottom: var(--topMenuHeight);
  z-index: 8004;
}

.customLogo.wideLogo {
  width: calc(var(--topMenuHeight) / var(--logoAspect));
}

.customLogo.shortLogo {
  width: var(--topMenuHeight);
}

.customLogo img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.topMenuSessionButtons {
  position: fixed;
  width: calc((1 - var(--topMenuWidth)) / 2 * 100% - 20px);
  height: var(--topMenuHeight);
  top: 0px;
  right: 0px;
  z-index: 8004;
}

.topMenuSpacer {
  height: var(--topMenuHeight);
}

.topMenuContainer {
  height: var(--topMenuHeight);
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  position: fixed;
  z-index: 8003;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #111;
  font-family: var(--mainFont);
  font-weight: 400;
  font-size: var(--mainFontSize);
  line-height: 26px;
  word-wrap: break-word;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.mainMenu {
  /*height: 100%;*/
  width: calc(var(--topMenuWidth) * 100%);
  margin-left: calc((1 - var(--topMenuWidth)) / 2 * 100%);
  overflow-x: hidden;
  text-align: center;
  /* box-shadow: 0 0 20px rgba(0,0,0,.15); */
}

.mainMenu .listRow {
  margin: 0px;
}

.mainMenuItem {
  background-color: #fff;
  text-align: left;
  float: left;
  transition: all .15s ease-in-out;
  list-style: none;
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.mainMenuItem:hover {
  /* color: #9b59b6; */
  font-weight: bold;
  box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, .15);
  cursor: pointer;
}

.mainMenuItem:hover .subMenu {
  display: block;
}

.subMenu {
  display: none;
  list-style: none;
  top: 100%;
  left: 0;
  z-index: 1000;
  padding: 12px 0 1px;
  /*min-width: 200px;*/
  text-align: left;
  text-transform: initial;
}

.subMenuItem {
  color: #111;
  font-weight: initial;
  margin-bottom: 0.5em;
}

.subMenuItem:hover {
  font-weight: bold;
}

.mainMenu .paginationButton.previous {
  top: 10px;
  left: calc((1 - var(--topMenuWidth)) / 2 * 100% - 20px);
}

.mainMenu .paginationButton.next {
  top: 10px;
  right: calc((1 - var(--topMenuWidth)) / 2 * 100% - 20px);
}