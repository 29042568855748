.bottomContainer {
  width: calc(var(--mainColumnWidth) * 100%);
  padding-left: 18px;
  padding-right: 18px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: white;
}

.bottomContainer>div {
  margin: 2em 0;
}

.bottomContainer div div {
  text-align: left;
}

.clickableTitle {
  margin: 0.5em 0;
}

.listTitle {
  line-height: 26px;
  color: #111;
  font-family: 'Lato';
  margin-bottom: 25px;
  margin-top: 0px;
}

.listMenu {
  border-radius: 5px;
}

.fakePlay {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}

.fakePlay:hover {
  color: rgba(255, 255, 255, 0.8)
}

/* override ItemList::extra.css */
:root {
  --BottomSectionTextFontSize: 12px;
  --BottomSectionTitleFontSize: 14px;
}

.bottomContainer .listItem .text {
  font-size: var(--BottomSectionTextFontSize);
  padding: 0;
}

.bottomContainer .listItem .title {
  font-size: var(--BottomSectionTitleFontSize);
}
