.menuSelectorContainer {
  width: calc(var(--mainColumnWidth) * 100%);
  font-family: var(--mainFont);
  font-size: var(--mainFontSize);
  padding-left: 18px;
  padding-right: 18px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: white;
}

.listMenu {
  border-radius: 5px;
}

.listMenu:hover {
  width: 110%;
  height: 110%;
  transform: translate(-5%, -5%);
  transition-property: all;
  transition-duration: .3s;
}