
:root {
  --middleBarFontSize: 16px;
}

.middleBarContainer {
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #111;
  font-family: 'Lato';
  font-weight: 400;
  font-size: var(--middleBarFontSize);
  line-height: 26px;
}

.middleBarContent {
  position: relative;
  width: calc( var(--mainColumnWidth) * 100%);
  background: rgb(243,243,243);
  padding: 20px 0;
  padding-left: 18px;
  padding-right: 18px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.middleBarContent h2 {
  margin: 0px;
}
