.paginationContainer {
  padding-top: 4px;
  margin-bottom: 4px;
  min-height: 43px;
  clear: both;
  position: relative;
  text-align: center;
  display: block;
  box-sizing: border-box;
  color: #111;
  font-family: var(--mainFont);
  font-weight: 400;
  font-size: var(--mainFontSize);
  line-height: 26px;
  word-wrap: break-word;
}

.paginationButton {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  /*height: 32px;*/
  /*min-width: 32px;*/
  /*padding: 5px 10px;*/
  padding: 2px 5px;
  color: #111;
  transition: all .15s ease-in-out;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Lato';
  font-weight: 400;
  border-radius: 5px;
}

.paginationButton:hover {
  /* color: #fff; */
  font-size: larger;
  background-color: var(--main-bg-hover-color);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .3);
  cursor: pointer;
}

.paginationButton.previous,
.paginationButton.next {
  /*padding: 9px 14px;*/
  /*height: 40px;*/
  position: absolute;
  /*min-width: 130px;*/
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  text-transform: uppercase;
  /* color: #fff; */
  background-color: var(--main-bg-color);
}

.paginationButton.previous:hover,
.paginationButton.next:hover {
  background-color: var(--main-bg-hover-color);
}

.paginationButton.previous {
  left: 0;
}

.paginationButton.next {
  right: 0;
}

.paginationButton.current {
  background: rgba(17, 17, 17, .1);
}

.paginationButton.dots:hover {
  color: #111;
  background: 0 0;
  box-shadow: none;
  cursor: auto;
}

.listRow {
  text-align: center;
  margin: 1%;
}

.listItem {
  --itemsPerRow: 1;
  padding: 0 0.8em;
  display: inline-block;
  width: calc(1 / var(--itemsPerRow) * 100%);
  vertical-align: top;
  height: 100%;
}