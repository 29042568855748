:root {
  --noContentFontSize: 14px;
}

.backgroundMainPlayer {
  background: #000;
}

.mainPlayerContainer {
  width: calc(var(--mainColumnWidth) * 100%);
  height: 0px;
  padding-bottom: calc(var(--mainColumnWidth) * var(--mainPlayerAspect) * 100%);
  background: #000;
  -webkit-backface-visibility: hidden;
  position: relative;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  color: #111;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 0 auto;
  z-index: 1;
}

.mainPlayerContainer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0px;
}

.noContent {
  font-weight: 400;
  font-family: var(--mainFont);
  font-size: var(--noContentFontSize);
  line-height: 26px;
  text-transform: uppercase;
  background-color: white;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: calc(var(--mainColumnWidth) * 100%);
  border: 1px solid lightgrey;
}