.password-input {
    display: flex;
}

.password-input .form-control {
    border-radius: 5px 0 0 5px;
}

.password-input .btn {
    border-radius: 0 5px 5px 0;
}